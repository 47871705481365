<template>
	<div class="news-mobile" v-if="isMobile">
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>
		<div class="main-content">

			<!-- 列表 -->
			<div v-show="!showDetail">
				<div class="row-me row-center flex-wrap margin-top50">
					<div class="item-view column-me anim-bigger-small0" v-for="(item,index) in newsArray" :key="index"
						@click="clickItem(item,index)">
						<div class="title1">{{item.month_day}}<span class="title2 margin-left10">{{item.year}}</span>
						</div>
						<img :src="item.cover" class="img1 margin-top25">
						<div class="title3 line1 margin-top27">{{item.title}}</div>
						<div class="title4 line2 margin-top15">{{item.content}}</div>
						<div class="bottom-div row-me row-center flex-sb margin-top20">
							<div class="title5" v-if="language==='0'">了解更多</div>
							<div class="title5" v-if="language==='1'">Learn more</div>
							<img src="../assets/img/san_right.png" class="img-san">
						</div>
					</div>
				</div>
				<div class="row-me center-all ">
					<el-pagination background @current-change="handleCurrentChange" :current-page.sync="pageNo"
						layout="prev, pager, next" :total="total" :pager-count="3">
					</el-pagination>
				</div>
			</div>

			<!-- 新闻详情 -->
			<div v-show="showDetail">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/home' }">
						<img src="../assets/img/icon_home.png" class="img-home">
						{{language==='0'?'首页':'Home Page'}}
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<span class="pointer" @click="clickNews">
							{{language==='0'?'新闻资讯':'News information'}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<span style="color: #0B0F0F;">{{selectItem.title}}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>

				<div class="title margin-top30">
					{{selectItem.title}}
				</div>
				<div class="margin-top30 row-me row-center flex-sb">
					<div class="title1">{{selectItem.transfer_time}}</div>
					<div class="row-me row-center">
						<img src="../assets/img/icon_liulan.png" class="img-home">
						<div class="title1 margin-left10">
							{{language==='0'?'浏览量':'Views'}}：{{selectItem.views}}
						</div>
					</div>
				</div>
				<div class="content margin-top50" v-html="selectItem.content"></div>
				<div class="row-me row-center flex-end1 margin-top80">
					<img src="../assets/img/left_d.png" class="img-left pointer-ban" v-if="selectIndex===0">
					<img src="../assets/img/left.png" class="img-left pointer anim-bigger-small" v-else
						@click="clickChangeLeft">
					<div class="line"></div>
					<img src="../assets/img/right_d.png" class="img-left pointer-ban"
						v-if="selectIndex===newsArray.length-1">
					<img src="../assets/img/right.png" class="img-left pointer anim-bigger-small" v-else
						@click="clickChangeRight">
				</div>
			</div>

		</div>
	</div>

	<div class="news" v-else>
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>
		<div class="main-content">

			<!-- 列表 -->
			<div v-show="!showDetail">
				<div class="row-me row-center flex-wrap margin-top50">
					<div class="item-view column-me" v-for="(item,index) in newsArray" :key="index"
						@click="clickItem(item,index)">
						<div class="title1">{{item.month_day}}<span class="title2 margin-left10">{{item.year}}</span>
						</div>
						<img :src="item.cover" class="img1 margin-top25">
						<div class="title3 line1 margin-top27">{{item.title}}</div>
						<div class="title4 line2 margin-top15">{{item.content}}</div>
						<div class="bottom-div row-me row-center flex-sb margin-top40">
							<div class="title5" v-if="language==='0'">了解更多</div>
							<div class="title5" v-if="language==='1'">Learn more</div>
							<img src="../assets/img/san_right.png" class="img-san">
						</div>
					</div>
				</div>
				<div class="row-me center-all ">
					<el-pagination background @current-change="handleCurrentChange" :current-page.sync="pageNo"
						layout="prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</div>

			<!-- 新闻详情 -->
			<div v-show="showDetail">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/home' }">
						<img src="../assets/img/icon_home.png" class="img-home">
						{{language==='0'?'首页':'Home Page'}}
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<span class="pointer" @click="clickNews">
							{{language==='0'?'新闻资讯':'News information'}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<span style="color: #0B0F0F;">{{selectItem.title}}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>

				<div class="title margin-top50">
					{{selectItem.title}}
				</div>
				<div class="margin-top40 row-me row-center">
					<div class="title1">{{selectItem.transfer_time}}</div>
					<img src="../assets/img/icon_liulan.png" class="img-home margin-left150">
					<div class="title1 margin-left10">
						{{language==='0'?'浏览量':'Views'}}：{{selectItem.views}}
					</div>
				</div>
				<div class="content margin-top80" v-html="selectItem.content"></div>
				<div class="row-me row-center flex-end1 margin-top80">
					<img src="../assets/img/left_d.png" class="img-left pointer-ban" v-if="selectIndex===0">
					<img src="../assets/img/left.png" class="img-left pointer anim-bigger-small" v-else
						@click="clickChangeLeft">
					<div class="line"></div>
					<img src="../assets/img/right_d.png" class="img-left pointer-ban"
						v-if="selectIndex===newsArray.length-1">
					<img src="../assets/img/right.png" class="img-left pointer anim-bigger-small" v-else
						@click="clickChangeRight">
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				newsArray: [],
				pageNo: 1,
				total: 1000,
				showDetail: false, //显示详情页
				selectItem: {},
				selectId: '',
				selectIndex: -1, //点击的下标，详情切换用
				language: '', //0中文，1英文
				isMuted: true, //视频是否静音
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.pageNo = 1
					this.getData()
					this.selectId = this.$route.query.id
					if (this.selectId !== null && this.selectId !== undefined) {
						this.getDetail()
					}
				}
			},
			$route: {
				handler(to, from) {
					if (to.query.id === null || to.query.id === undefined) {
						this.showDetail = false
					} else {
						this.showDetail = true
					}
				},
				// 深度观察监听
				deep: true
			}
		},
		computed: {
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.pageNo = 1
			this.getData()
			this.selectId = this.$route.query.id
			if (this.selectId !== null && this.selectId !== undefined) {
				this.getDetail()
			}
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
		},
		mounted() {

		},
		methods: {
			clickNews() {
				this.$router.push({
					path: '/news'
				})
				this.showDetail = false
				this.getData()
			},
			clickItem(item, index) {
				this.selectId = item.id
				this.selectIndex = index
				this.$router.push({
					path: '/news',
					query: {
						id: this.selectId
					}
				})
				this.getDetail()
			},
			clickChangeLeft() {
				this.selectIndex--
				this.selectId = this.newsArray[this.selectIndex].id
				if (this.selectId !== null && this.selectId !== undefined) {
					this.getDetail()
				}
			},
			clickChangeRight() {
				this.selectIndex++
				this.selectId = this.newsArray[this.selectIndex].id
				if (this.selectId !== null && this.selectId !== undefined) {
					this.getDetail()
				}
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getData()
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/news') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/news', {
					page: this.pageNo,
					limit: 9,
				}).then((res) => {
					if (res) {
						this.total = res.data.total
						this.newsArray = res.data.data
						if (this.selectId !== null && this.selectId !== undefined) {
							for (var i = 0; i < this.newsArray.length; i++) {
								if (this.selectId == this.newsArray[i].id) {
									this.selectIndex = i
								}
							}
						}
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			getDetail() {
				this.$postRequest('/web/newsInfo', {
					id: this.selectId
				}).then((res) => {
					if (res) {
						this.selectItem = res.data
						this.showDetail = true
						this.$bus.$emit('scrollTo', 700)
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.news-mobile {
		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 20px;

			.img-home {
				width: 20px;
				height: 20px;
				object-fit: cover;
				margin-bottom: -2px;
			}

			.title {
				font-size: 28px;
				color: #0B0F0F;
			}

			.title1 {
				font-size: 18px;
				color: #999999;
			}

			.content {
				font-size: 22px;
				color: #0B0F0F;

				/deep/ img {
					max-width: 100%;
				}
			}

			.line {
				width: 1px;
				height: 20px;
				background-color: #DDDDDD;
				margin: 0 20px;
			}

			.img-left {
				width: 20px;
				height: 20px;
				object-fit: cover;
				padding: 10px;
			}
		}

		.item-view {
			cursor: pointer;
			border: 1px solid #EEEEEE;
			margin-bottom: 60px;
			padding: 20px;

			.title1 {
				font-size: 30px;
				color: #333333;
			}

			.title2 {
				font-size: 18px;
				color: #999999;
			}

			.img1 {
				width: 100%;
				height: 172px;
				object-fit: cover;
			}

			.title3 {
				font-size: 22px;
				color: #000000;
			}

			.title4 {
				font-size: 16px;
				color: #999999;
				line-height: 25px;
			}

			.bottom-div {
				width: 100%;
				border-bottom: 2px solid #1D9E9B;
				padding-bottom: 18px;
			}

			.title5 {
				font-size: 16px;
				color: #1D9E9B;
			}

			.img-san {
				width: 16px;
				height: 16px;
				object-fit: scale-down;
			}
		}

	}


	.news {
		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 100px 278px;

			.img-home {
				width: 20px;
				height: 20px;
				object-fit: scale-down;
				margin-bottom: -2px;
			}

			.title {
				font-size: 48px;
				color: #0B0F0F;
				line-height: 80px;
			}

			.title1 {
				font-size: 22px;
				color: #999999;
			}

			.content {
				font-size: 22px;
				color: #0B0F0F;
				line-height: 40px;

				/deep/ img {
					max-width: 100%;
				}
			}

			.line {
				width: 1px;
				height: 40px;
				background-color: #DDDDDD;
				margin: 0 20px;
			}

			.img-left {
				width: 20px;
				height: 30px;
				object-fit: scale-down;
				padding: 10px;
			}
		}

		.item-view {
			width: 280px;
			cursor: pointer;
			border: 1px solid #EEEEEE;
			margin-bottom: 60px;
			margin-right: 82px;
			padding: 40px 56px;

			&:nth-child(3n) {
				margin-right: 0;
			}

			&:hover {
				box-shadow: 0px 10px 50px rgba(11, 15, 15, 0.1);

				.bottom-div {
					width: 100%;
					animation: bottomAnim 0.5s 1 ease-out;
				}

				@keyframes bottomAnim {
					0% {
						width: 50%;
					}

					100% {
						width: 100%;
					}
				}
			}

			.title1 {
				font-size: 30px;
				color: #333333;
			}

			.title2 {
				font-size: 18px;
				color: #999999;
			}

			.img1 {
				width: 288px;
				height: 172px;
				object-fit: cover;
			}

			.title3 {
				font-size: 22px;
				color: #000000;
			}

			.title4 {
				font-size: 16px;
				color: #999999;
				line-height: 25px;
			}

			.bottom-div {
				width: 50%;
				border-bottom: 2px solid #1D9E9B;
				padding-bottom: 18px;
			}

			.title5 {
				font-size: 16px;
				color: #1D9E9B;
			}

			.img-san {
				width: 16px;
				height: 16px;
				object-fit: scale-down;
			}
		}

	}
</style>
